import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home1 from './Pages/Home1';
import Home2 from './Pages/Home2';
import Home3 from './Pages/Home3';
import Home4 from './Pages/Home4';
import Home5 from './Pages/Home5';
import Home6 from './Pages/Home6';

import About1 from './Pages/About1';
import About2 from './Pages/About2';

import Services1 from './Pages/Services1';
import Services2 from './Pages/Services2';
import ServiceDetail from './Pages/ServiceDetail';

import Team1 from './Pages/Team1';
import Team2 from './Pages/Team2';
import TeamDetail from './Pages/TeamDetail';

import OurHistory from './Pages/OurHistory';
import FontIcons from './Pages/FontIcons';
import Error from './Pages/Error';

import ProjectGrid3 from './Pages/ProjectGrid3';
import ProjectGridNoGap3 from './Pages/ProjectGridNoGap3';
import ProjectGrid4 from './Pages/ProjectGrid4';
import ProjectGridNoGap4 from './Pages/ProjectGridNoGap4';
import ProjectGrid5 from './Pages/ProjectGrid5';
import ProjectGridNoGap5 from './Pages/ProjectGridNoGap5';

import ProjectMasonary3 from './Pages/ProjectMasonary3';
import ProjectMasonaryNoGap3 from './Pages/ProjectMasonaryNoGap3';
import ProjectMasonary4 from './Pages/ProjectMasonary4';
import ProjectMasonaryNoGap4 from './Pages/ProjectMasonaryNoGap4';
import ProjectMasonary5 from './Pages/ProjectMasonary5';
import ProjectMasonaryNoGap5 from './Pages/ProjectMasonaryNoGap5';

import ProjectCorousel from './Pages/ProjectCorousel';
import ProjectDetail1 from './Pages/ProjectDetail1';
import ProjectDetail2 from './Pages/ProjectDetail2';

import BlogGrid from './Pages/BlogGrid';
import BlogListing from './Pages/BlogListing';
import BlogMasonary from './Pages/BlogMasonary';
import BlogSingle from './Pages/BlogSingle';
import PostRightSidebar from './Pages/PostRightSidebar';

import ShopGrid from './Pages/ShopGrid';
import ShopList from './Pages/ShopList';
import ShopDetail from './Pages/ShopDetail';
import ShopAccount from './Pages/ShopAccount';
import ShopCart from './Pages/ShopCart';
import ShopCheckout from './Pages/ShopCheckout';

import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Home5} />
                        <Route path='/home-2' exact component={Home2} />
                        <Route path='/home-3' exact component={Home3} />
                        <Route path='/home-4' exact component={Home4} />
                        <Route path='/home-5' exact component={Home5} />
                        <Route path='/home-6' exact component={Home6} />

                        <Route path='/about-1' exact component={About1} />
                        <Route path='/about-2' exact component={About2} />

                        <Route path='/services-1' exact component={Services1} />
                        <Route path='/services-2' exact component={Services2} />
                        <Route path='/services-detail' exact component={ServiceDetail} />

                        <Route path='/team-1' exact component={Team1} />
                        <Route path='/team-2' exact component={Team2} />
                        <Route path='/team-single' exact component={TeamDetail} />

                        <Route path='/our-history' exact component={OurHistory} />
                        <Route path='/icon-font' exact component={FontIcons} />
                        <Route path='/error-404' exact component={Error} />

                        <Route path='/project-grid-3-columns' exact component={ProjectGrid3} />
                        <Route path='/project-grid-3-columns-no-gap' exact component={ProjectGridNoGap3} />
                        <Route path='/project-grid-4-columns' exact component={ProjectGrid4} />
                        <Route path='/project-grid-4-columns-no-gap' exact component={ProjectGridNoGap4} />
                        <Route path='/project-grid-5-columns' exact component={ProjectGrid5} />
                        <Route path='/project-grid-5-columns-no-gap' exact component={ProjectGridNoGap5} />

                        <Route path='/project-masonry-3-columns' exact component={ProjectMasonary3} />
                        <Route path='/project-masonry-3-columns-no-gap' exact component={ProjectMasonaryNoGap3} />
                        <Route path='/project-masonry-4-columns' exact component={ProjectMasonary4} />
                        <Route path='/project-masonry-4-columns-no-gap' exact component={ProjectMasonaryNoGap4} />
                        <Route path='/project-masonry-5-columns' exact component={ProjectMasonary5} />
                        <Route path='/project-masonry-5-columns-no-gap' exact component={ProjectMasonaryNoGap5} />

                        <Route path='/project-carousel' exact component={ProjectCorousel} />
                        <Route path='/project-detail1' exact component={ProjectDetail1} />
                        <Route path='/project-detail2' exact component={ProjectDetail2} />

                        <Route path='/blog-grid' exact component={BlogGrid} />
                        <Route path='/blog-listing' exact component={BlogListing} />
                        <Route path='/blog-masonry' exact component={BlogMasonary} />
                        <Route path='/blog-single' exact component={BlogSingle} />
                        <Route path='/post-right-sidebar' exact component={PostRightSidebar} />

                        <Route path='/shop-grid' exact component={ShopGrid} />
                        <Route path='/shop-list' exact component={ShopList} />
                        <Route path='/shop-detail' exact component={ShopDetail} />
                        <Route path='/shop-account' exact component={ShopAccount} />
                        <Route path='/shop-cart' exact component={ShopCart} />
                        <Route path='/shop-checkout' exact component={ShopCheckout} />

                        <Route path='/faq' exact component={Faq} />
                        <Route path='/contact-us' exact component={ContactUs} />

                        <Route component={Error} />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;