import React from 'react';
import Header4 from './../Common/Header4';
import Footer2 from './../Common/Footer2';
import Slider5 from './../Elements/Slider5';
import About4 from './../Elements/About4';
import WhatWeDo6 from './../Elements/WhatWeDo6';
import Statistics1 from './../Elements/Statistics1';
import Services6 from './../Elements/Services6';
import Services7 from './../Elements/Services7';
import ClientsLogo2 from './../Elements/ClientsLogo2';
class Home5 extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Slider5 />
                    <About4 />
                    <Statistics1 />
                    <WhatWeDo6 />
                    {/* <Services6 />
                    <Services7 /> */}
                    {/* <ClientsLogo2 bgcolor="bg-gray" /> */}
                </div>
                <Footer2 />
            </>
        );
    };
};

export default Home5;